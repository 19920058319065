<template>
   <section class="cont Income_details">
      <!-- 面包屑  /member_user/user_list   /member_management -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>数据中台</el-breadcrumb-item>
            <el-breadcrumb-item >SCRN</el-breadcrumb-item>
            <el-breadcrumb-item to="/member_user/member_management">会员管理</el-breadcrumb-item>
            <el-breadcrumb-item>积分明细</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <!-- 查询框 -->
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class='search-item'>
                  <label>订单号：</label>
                  <el-input  class="width-250" v-model="orderNo"></el-input>
               </el-row>
<!--               <el-row class='search-item'>-->
<!--                  <label>订单类型：</label>-->
<!--                  <el-select class="width-250" v-model="orderType" multiple>-->
<!--                     <el-option-->
<!--                           v-for="(item,index) in dictData['order-source']"-->
<!--                           :key="index"-->
<!--                           :label="item"-->
<!--                           :value="index">-->
<!--                     </el-option>-->
<!--                  </el-select>-->
<!--               </el-row>-->
               <el-button class="bg-gradient" @click="queryEvent">搜索</el-button>
               <el-button class="bg-gradient" @click="resetEvent">重置</el-button>
            </el-row>
         </el-row>
         <!-- 主体内容 -->
         <div class="table-box">
            <el-table
                  v-loading="loading"
                  :data="tableData"
                  border fit
                  style="width: 100%"
                  :stripe="true"
                  :header-cell-style="{background: '#f5f8f9'}">
               <el-table-column label="序号" type="index" width="55"></el-table-column>
               <el-table-column label='积分变动时间' prop="integralTime"></el-table-column>
               <el-table-column label='变动类型'>
                  <template slot-scope="scope">
                     {{scope.row.source | filterSourceOrder(that)}}
                  </template>
               </el-table-column>
               <el-table-column label='获得/消费积分'>
                  <template slot-scope="scope">
                     {{scope.row.integral}}
                  </template>
               </el-table-column>
               <el-table-column label='备注' prop="remark"></el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
         </div>
      </el-row>
   </section>
</template>

<script>
import { mapState } from 'vuex'
import { urlObj } from '@/api/interface'
import { getDict, verificationRule } from '@/common/js/common'
export default {
   name: "Income_details",
   data(){
      return{
         that:this,
         orderNo:'',       //订单号
         orderSource:'',   //订单来源
         orderType:'',     //订单类型

         loading:true,              // 表格加载效果
         tableData:[],              // 表格数据
         total: 0,                  // 用户列表总条目数
         page: 1,                   // 当前页 默认第一页
         limit: 0,                 // 每页显示数
      }
   },
   computed: {
      ...mapState(['dictData','hotelInfo'])
   },
   mounted() {
      this.limit = sessionStorage.getItem('pageSize') * 1
      getDict(["order-source"])
      this.getList()
   },
   filters: {
      filterSourceOrder(val,that){
         switch (val) {
            case 'ORDER':
               return val = '订单'

         }
      },
   },
   methods:{
      //获取表格数据
      getList(){


         const url = urlObj.getintegrallist + `?limit=${this.limit}&page=${this.page}`
         let param = {
            hotelId:this.hotelInfo.id,
            memberId:this.$route.query.id

         }
         this.$axios.post(url,param).then(res => {
            if (res.success) {
               this.loading = false
               this.tableData = res.records
               this.total = res.total
            }
         })
      },
      // 搜索
      queryEvent(){
         this.getList()
      },
      // 重置
      resetEvent(){
         this.orderNo = ''
         this.orderSource = ''
         this.orderType = ''
      },



      // 改变每页数
      pageChange(num) {
         this.limit = num
         this.getList()
      },
      // 改变当前页
      handlePaging(num) {
         this.page = num
         this.getList()
      },
   },
}
</script>

<style scoped lang="scss">
.Income_details{

}
</style>
